<template>
  <div id="zgubidoo-default">
    <UCard
        class="w-full h-screen flex flex-col"
        :ui="{
          header: {
            base: 'flex-none',
          },
          body: {
            base: 'flex-1 overflow-y-auto relative',
          },
          footer: {
            base: 'flex-none',
            padding: 'px-4 py-2 sm:px-6',
          },
        }"
    >
      <template #header>
        <header class="flex justify-between">
          <div class="flex gap-x-4 items-center justify-between lg:hidden">
            <UButton :padded="false" aria-label="Menu główne" class="flex visible" color="gray"
                     icon="i-ri-menu-line"
                     square variant="ghost" @click="isMenuModalOpen = true"/>
          </div>
          <div class="flex items-center">
            <ULink to="/" class="flex" title="Zgubidoo.pl" aria-label="Zgubidoo.pl">
              <img v-if="'dark' === $colorMode.value" src="~/assets/gfx/logotypes/zgubidoo-light.svg" width="100" height="20" alt="Zgubidoo.pl"/>
              <img v-else src="~/assets/gfx/logotypes/zgubidoo-dark.svg" width="100" height="20" alt="Zgubidoo.pl"/>
            </ULink>
          </div>
          <div class="flex gap-x-4 items-center justify-between">
            <QuerySearch class="hidden sm:block" @showFilterOverlay="isFilterModalOpen = true"/>
            <UButton
                class="hidden md:flex"
                icon="i-ri-add-circle-line"
                label="Dodaj"
                aria-label="Dodaj ogłoszenie"
                size="md"
                @click="isPostCategorySelectorModalOpen = true"
            />
            <ULink to="/profiles/me/" class="flex" title="Profil użytkownika" aria-label="Profil użytkownika">
              <UAvatar v-if="!!user" :src="user?.image" size="xs" :alt="user.name ?? 'Avatar'"/>
              <UAvatar v-else icon="i-ri-user-line" size="xs" alt="Avatar"/>
            </ULink>
            <UButton
                size="md"
                square
                :padded="false"
                color="white"
                variant="ghost"
                aria-label="Powiadomienia"
                class="flex"
                icon="i-ri-notification-3-line"
                title="Powiadomienia"
                to="/notifications/"
            />
          </div>
        </header>
      </template>
      <main class="flex flex-wrap justify-between">
        <aside class="hidden w-1/3 lg:block md:w-1/4">
          <LazyMenuSidebar/>
          <div class="mt-4">
            <LazyFilterSidebar/>
          </div>
        </aside>
        <div class="w-full lg:w-2/3">
          <div>
            <LazyUAlert
                v-if="1 === randomAlert"
                class="mb-4"
                color="red"
                description="Jesteśmy w fazie rozwoju. Korzystanie z serwisu może być utrudnione."
                icon="i-ri-error-warning-line"
                title="Strona w budowie!"
                variant="subtle"
            />
            <LazyUAlert
                v-if="2 === randomAlert"
                class="mb-4"
                color="primary"
                icon="i-ri-flask-line"
                title="Zapraszamy entuzjastów testowania 🙃"
                variant="subtle"
            />
            <LazyUAlert
                v-if="3 === randomAlert"
                :actions="[{
              icon: 'i-ri-mail-send-line',
              to: 'mailto:bartek@nuvola.pl?subject=Sugestie%20zgubidoo.pl',
              title: 'Wyślij mail z sugestiami',
              'aria-label': 'Wyślij mail z sugestiami',
            }]"
                class="mb-4"
                color="sky"
                title="Masz pytania lub sugestie? Daj nam znać!"
                variant="subtle"
            />
          </div>
          <div>
            <slot/>
          </div>
        </div>
      </main>
      <template #footer>
        <footer>
          <nav class="lg:hidden">
            <ul class="flex items-center text-2xl text-center text-gray-800 dark:text-gray-200">
              <li class="flex-1">
                <ULink to="/" exact aria-label="Strona główna" class="">
                  <UIcon name="i-ri-home-4-fill"></UIcon>
                </ULink>
              </li>
              <li class="flex-1">
                <ULink to="/saved-posts/" exact aria-label="Ulubione">
                  <UIcon name="i-ri-heart-line"></UIcon>
                </ULink>
              </li>
              <li class="grow">
                <UButton
                    :padded="false"
                    :ui="{icon: {size: {'xxl': 'w-12 h-12'}}}"
                    aria-label="Dodaj ogłoszenie"
                    icon="i-ri-add-circle-line" size="xxl" square type="button" variant="ghost"
                    @click="isPostCategorySelectorModalOpen = true"/>
              </li>
              <li class="flex-1">
                <ULink to="/profiles/me/" aria-label="Profil użytkownika" class="leading-none">
                  <UIcon name="i-ri-user-line"></UIcon>
                </ULink>
              </li>
              <li class="flex-1">
                <ULink to="/settings/" aria-label="Ustawienia">
                  <UIcon name="i-ri-settings-line"></UIcon>
                </ULink>
              </li>
            </ul>
          </nav>
          <div class="hidden lg:flex justify-between">
            <div class="space-x-6">
              <ULink active-class="underline" class="hover:underline" to="/pages/about-us/">O nas</ULink>
              <ULink active-class="underline" class="hover:underline" to="/pages/press/">Prasa</ULink>
              <ULink active-class="underline" class="hover:underline" to="/pages/contact/">Kontakt</ULink>
            </div>
            <div class="space-x-6">
              <ULink active-class="underline" class="hover:underline" to="/pages/terms-and-conditions/">Regulamin
              </ULink>
              <ULink active-class="underline" class="hover:underline" to="/pages/privacy-policy/">Polityka Prywatności
              </ULink>
            </div>
          </div>
          <LazyUSlideover v-model="isMenuModalOpen" side="left">
            <LazyMenuSidebar :is-overlay="true" @close-overlay="isMenuModalOpen = false"/>
          </LazyUSlideover>
          <LazyUModal v-model="isPostCategorySelectorModalOpen">
            <UCard>
              <template #header>
                <div class="flex items-center justify-between">
                  <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                    Co chcesz dodać?</h3>
                  <UButton color="gray" icon="i-ri-close-line"
                           variant="ghost" @click="isPostCategorySelectorModalOpen = false"/>
                </div>
              </template>
              <div class="space-y-4">
                <p class="text-gray-400">
                  Wybierz kategorię ogłszenia jakie chcesz dodać.
                </p>
                <UButton
                    v-for="(category) in categoryRepository.find().items"
                    :to="`/posts/create/?post[category]=${category.id}`"
                    block
                    size="xl" @click="isPostCategorySelectorModalOpen = false"
                >
                  {{ category.name }}
                </UButton>
                <UButton block size="xl" variant="ghost" @click="isPostCategorySelectorModalOpen = false">
                  Anuluj
                </UButton>
              </div>
            </UCard>
          </LazyUModal>
        </footer>
      </template>
    </UCard>
  </div>
</template>

<script setup lang="ts">
import QuerySearch from "~/components/QuerySearch.vue";
import CategoryRepository from "~/repositories/CategoryRepository";

const categoryRepository = new CategoryRepository();

const {data} = useAuth();
const user = data.value?.user;

const isMenuModalOpen = ref(false);
const isPostCategorySelectorModalOpen = ref(false);
const isFilterModalOpen = ref(false);
const randomAlert = computed(() => Math.floor(Math.random() * 3) + 1);
</script>
